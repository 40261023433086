// src/screens/IntroScreen.js

import React, { useState, useEffect } from 'react';
import { logMessage } from '../../utils/helpers';
import imagePaths from '../../assets/imagePaths';
import './authScreen.css';
import { FaEye, FaEyeSlash, FaUser, FaEnvelope, FaKey } from "react-icons/fa";
import { Link } from 'react-router-dom';
import api from '../../api/api';
import { UserData } from '../../models/UserModel';
import { ApiResponse } from '../../models/ApiResponseModel';
import { useNavigate } from 'react-router-dom';
import paths from '../../utils/constants';

const AuthScreen = ({ startLoading, stopLoading }) => {
    const [showSignUp, setShowSignUp] = useState(true);
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [showPassword, setShowNewPassword] = useState(false);
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        password: ''
    });
    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    });


    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) navigate(paths.HOME_SCREEN)
    }, [navigate]);

    const toggleNewPasswordVisibility = () => setShowNewPassword(!showPassword)

    const handleCheckboxChange = (e) => setIsChecked(e.target.checked)
    
    const handleSignUp = async (event) => {
        if (!isChecked) {
            logMessage("Please agree to the Terms & Conditions", 'alert');
            return;
        }
        event.preventDefault();
        startLoading();
        userData.deviceType = 'web';
        try {
            const response = await api.signUp(userData);
            const apiResponse = new ApiResponse(
                response.status,
                response.message
            );
            if (apiResponse.status == 1) {
                const signUpResponse = new UserData(response.data);
                localStorage.setItem('user_data', JSON.stringify(signUpResponse.data));
                localStorage.setItem('user_email', signUpResponse.email);
                logMessage(signUpResponse.token);
                localStorage.setItem('token', signUpResponse.token);
                localStorage.setItem('user_name', signUpResponse.name);
                localStorage.setItem('profile_picture', signUpResponse.profile_picture);
                localStorage.setItem('isVerified', signUpResponse.isPremium);
                setTimeout(() => {
                    navigate(paths.HOME_SCREEN);
                }, 1000);

            } else {
                logMessage(apiResponse.message, 'alert');
            }
        } catch (error) {
            logMessage(error);
        } finally {
            stopLoading();
        }
    };
    const handleLogin = async (event) => {
        event.preventDefault();
        startLoading();
        loginData.deviceType = 'web';
        try {
            const response = await api.login(loginData);
            const apiResponse = new ApiResponse(
                response.status,
                response.message
            );
            if (apiResponse.status == 1) {
                const signUpResponse = new UserData(response.data);
                localStorage.setItem('user_data', JSON.stringify(signUpResponse.data));
                localStorage.setItem('user_email', signUpResponse.email);
                logMessage(signUpResponse.token);
                localStorage.setItem('token', signUpResponse.token);
                localStorage.setItem('user_name', signUpResponse.name);
                localStorage.setItem('profile_picture', signUpResponse.profile_picture);
                localStorage.setItem('isVerified', signUpResponse.isPremium);
                setTimeout(() => {
                    navigate(paths.HOME_SCREEN);
                }, 1000);

            } else {
                logMessage(apiResponse.message, 'alert');
                stopLoading();
            }
        } catch (error) {
            logMessage(error);
            stopLoading();
        } finally {
            // stopLoading();
        }

    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData(prevUserData => ({
            ...prevUserData,
            [name]: value
        }));
    };
    const handleLoginChange = (event) => {
        const { name, value } = event.target;
        setLoginData(prevUserData => ({
            ...prevUserData,
            [name]: value
        }));
    };
    return (
        <div className='intro_screen_container' style={{ backgroundImage: `url(${imagePaths.auth_background})` }}>
            <div className="intro_content">
                <div className="left_content loginscreen">
                    <img className="intro_images" src={imagePaths.auth_side_image} alt="diamond" />
                </div>
                <div className="right_content intro_text_data">
                    <img className="authlogo" src={imagePaths.logOutLogo} ></img>
                    <div className='button_group'>
                        <div className='inner_group'>
                            <span className={showSignUp ? 'activespan' : ''} onClick={() => setShowSignUp(true)}>Sign Up</span>
                            <span className={!showSignUp ? 'activespan' : ''} onClick={() => setShowSignUp(false)}>Sign In</span>
                        </div>
                    </div>
                    {showSignUp ? (
                        <div className='sign_up_container outer_container'>
                            <h2>Create Account</h2>
                            <p>Please enter the requested details.</p>
                            <div className='form-container'>
                                <form onSubmit={handleSignUp}>
                                    <div className="form-item">
                                        <FaUser /><input type="text" placeholder='Full Name' name="name" value={userData.name} onChange={handleChange} required />
                                    </div>
                                    <div className="form-item">
                                        <FaEnvelope /> <input type="email" placeholder='Email Address' name="email" value={userData.email} onChange={handleChange} required />
                                    </div>
                                    <div className="form-item">
                                        <FaKey />
                                        <input type={showPassword ? 'text' : 'password'} placeholder='Password' name="password" value={userData.password} onChange={handleChange} required />
                                        <button className="passwordEye" type="button" onClick={toggleNewPasswordVisibility}>
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>
                                    <div className="form-item-checkbox">
                                        <input type="checkbox" id="agree" name="agree" required checked={isChecked} onChange={handleCheckboxChange} />
                                        <label htmlFor="agree">By checking the box, you agree to the <a target='blank' href={paths.TERMS}>Terms & Conditions</a> and our <a target='blank' href={paths.PRIVACY}>Privacy Policy.</a></label>
                                    </div>
                                    <div className='button-group intro-buttons'>
                                        <button className='colored-button click-buttons primary-button'>Sign Up</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    ) : (
                        <div className='sign_in_container outer_container'>
                            <h2>Welcome back!</h2>
                            <p>Please enter your sign-in details</p>
                            <div className='form-container'>
                                <form onSubmit={handleLogin}>
                                    <div className="form-item">
                                        <FaEnvelope /> <input type="email" placeholder='Email Address' name="email" value={loginData.email} onChange={handleLoginChange} required />
                                    </div>
                                    <div className="form-item">
                                        <FaKey />
                                        <input type={showPassword ? 'text' : 'password'} placeholder='Password' name="password" value={loginData.password} onChange={handleLoginChange} required />
                                        <button className="passwordEye" type="button" onClick={toggleNewPasswordVisibility}>
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>
                                    <div className="form-item-forgot">
                                        <Link to="/forgotpassword"><p>Forgot Password?</p></Link>
                                    </div>

                                    <div className='button-group intro-buttons'>
                                        <button className='colored-button click-buttons primary-button'>
                                            Sign In
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AuthScreen;
