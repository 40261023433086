import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logMessage, signOutUser } from "../../utils/helpers.js";
import { TbCircleKey } from "react-icons/tb";
import { GoShieldCheck } from "react-icons/go";
import Header from "../../components/common/header.js";
import imagePaths from "../../assets/imagePaths.js";
import paths from "../../utils/constants.js";
import api from "../../api/api.js";
import { ApiResponse } from "../../models/ApiResponseModel.js";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import { FiUserX } from "react-icons/fi";
import { MdOutlinePlaylistAddCheckCircle, } from "react-icons/md";
import { FaCamera, FaEye, FaEyeSlash } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import { UserData } from "../../models/UserModel.js";
import { SubModel } from "../../models/SubscriptionModel.js";

const styles = {
  otpContainer: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'center',
    margin: '15px 0 15px 0',
  },
  otpInput: {
    width: '65px',
    height: '25px',
    fontSize: '20px',
    textAlign: 'center',
    backgroundColor:'#fff',
    border: '1px solid #552F83',
    borderRadius: '4px',
    outline: 'none',
    transition: 'border-color 0.2s',
  },
};

const MyProfile = ({ startLoading, stopLoading }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [type, setType] = useState("personal");
  const [showPopup, setShowPopup] = useState(false);
  const [showSubPopUp, setShowSubPopUp] = useState(false);
  const [name, setName] = useState();
  const [showVerifyPopUp, setShowVerifyPopUp] = useState(false);
  const [imageChange, setImageChange] = useState(false);
  const [subId, setSubId] = useState("");
  const [userType, setUserType] = useState("");
  const [purchasedOn, setPurchasedOn] = useState("");
  const [expiryTime, setExpiryTime] = useState("");
  const [buttonLoading, showButtonLoading] = useState(false);
  const [interval, setInterval] = useState("");
  const [status, setStatus] = useState("");
  const [intervalCount, setIntervalCount] = useState("");
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem("user_email"));
  const [profile, setProfile] = useState();

  const [userData, setUserData] = useState({
    old_password: "",
    new_password: "",
    confirmpassword: "",
  });

  const [editFormData, setEditFormData] = useState({
    name: "",
    image: "",
  });

  const togglePopup = () => {
    logMessage("toggleclicked");
    setShowPopup(!showPopup);
    logMessage(showPopup);
  };

  const toggleNewPasswordVisibility = (passwordType) => {
    if (passwordType == "current") {
      setShowCurrentPassword(!showCurrentPassword);
    } else if (passwordType == "new") {
      setShowNewPassword(!showNewPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  useEffect(() => {
    
    const params = new URLSearchParams(location.search);
    const isViewPlans = params.get("viewplans") === "true";

    if (isViewPlans) {
      openSubscribeBox();
    }
    
  }, [location.search])

  const openSubscribeBox = () => {
    const sortButton = document.getElementById('showMainSubBox');
    if (sortButton) {
      sortButton.click();
    }
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const viewPlanParam = searchParams.get("viewplan");

    if (viewPlanParam === "true") {
      const sortButton = document.getElementById('showMainSubBox');
      if (sortButton) {
        sortButton.click();
      }
    }
  }, [location.search]);

  const Option = ({ calling, label, icon: Icon }) => {
    return (
      <div onClick={() => optionClick(calling)} className="settings-option">
        <div className="setting_labeled">
          <span className="icon_class">{Icon}</span>
          <span>{label}</span>
        </div>
        <span className="settings-option-arrow">›</span>
      </div>
    );
  };
  const refreshPage = () => {
    window.location.reload();
  };
  const handleChange = (typeSet) => {
    setType(typeSet);
  };

  const optionClick = (calling) => {
    switch (calling) {
      case "changePassword":
        setType("change_password");
        break;
      case "terms":
        // navigate(paths.TERMS);
        window.open(paths.TERMS, "_blank");
        break;
      case "privacy":
        // navigate(paths.PRIVACY);
        window.open(paths.PRIVACY, "_blank");
        break;
      case "support":
        // navigate(paths.PRIVACY);
        window.open("mailto:support@dreamerdesigns.com", "_blank");
        break;
      // case "about":
      //   // navigate(paths.ABOUTUS);
      //   window.open(paths.ABOUTUS, '_blank');
      //   break;
      case "delete":
        togglePopup();
        break;
      default:
        break;
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    setEditFormData((prevFormData) => ({
      ...prevFormData,
      name: event.target.value,
    }));
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const getTypeComponent = (type) => {
    const components = {
      personal: () => renderPersonalInfo(),
      settings: () => renderSettingsOptions(),
      change_password: () => changePasswordInfo(),
      // Add more types and corresponding rendering options here
    };

    const component = components[type];
    if (!component) {
      console.error(`Invalid type: ${type}`);
      return null;
    }

    return component();
  };

  const handleEditProfile = async (event) => {
    logMessage(editFormData);
    event.preventDefault();
    startLoading();
    try {
      const response = await api.editProfile(editFormData, imageChange);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status == 1) {
        // stopLoading();
        setTimeout(() => {
          getProfileData();
        }, 1000);
      } else {
        // console.log("here");
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      // stopLoading();
    }
  };

  const handleChangePassword = async (event) => {
    logMessage(editFormData);
    event.preventDefault();
    if (userData.confirmpassword != userData.new_password) {
      logMessage("Your confirm password mismatches with new password", "alert");
    } else {
      try {
        startLoading();
        const response = await api.changePassword(userData);
        const apiResponse = new ApiResponse(response.status, response.message);
        if (apiResponse.status == 1) {
          stopLoading();
          setUserData({
            old_password: "",
            new_password: "",
            confirmpassword: "",
          });
          setType("settings");
        } else {
          logMessage(apiResponse.message, "alert");
        }
      } catch (error) {
        logMessage(error);
      } finally {
        stopLoading();
      }
    }
  };

  const renderPersonalInfo = () => (
    <div className="personal_info">
      <form onSubmit={handleEditProfile}>
        <div className="form_container personal_form">
          <div className="form_fields">
            <label>Name:</label>
            <input
              type="text"
              name="design"
              onChange={handleNameChange}
              value={editFormData.name}
            />
          </div>
          <div className="form_fields">
            <label>Email:</label>
            <input
              type="text"
              style={{ color: "#808080" }}
              name="email"
              defaultValue={email}
              readOnly
            />
          </div>
        </div>
        <div className="button_fields">
          <button className="addProject" type="submit">
            Update
          </button>
        </div>
      </form>
    </div>
  );

  const changePasswordInfo = () => (
    <div className="personal_info changePassword">
      <p className="settingspassword">Settings {">"} Change password</p>
      <form onSubmit={handleChangePassword}>
        <div className="form_container personal_form">
          <div className="form_fields currentpassword">
            <label>Current password:</label>
            {/* <div> */}
            <input
              type={showCurrentPassword ? "text" : "password"}
              placeholder="Password"
              name="old_password"
              value={userData.old_password}
              onChange={handleFormChange}
              required
            />
            <button
              className="passwordEye"
              type="button"
              onClick={() => toggleNewPasswordVisibility("current")}
            >
              {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
            {/* </div> */}
          </div>
          <div className="form_fields">
            <label>New password:</label>
            <input
              type={showNewPassword ? "text" : "password"}
              placeholder="New"
              name="new_password"
              value={userData.new_password}
              onChange={handleFormChange}
              required
            />
            <button
              className="passwordEye"
              type="button"
              onClick={() => toggleNewPasswordVisibility("new")}
            >
              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="form_fields">
            <label>Confirm password:</label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm"
              name="confirmpassword"
              value={userData.confirmpassword}
              onChange={handleFormChange}
              required
            />
            <button
              className="passwordEye"
              type="button"
              onClick={() => toggleNewPasswordVisibility("confirm")}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>
        <div className="button_fields">
          <button className="addProject changeProfilePassword" type="submit">
            Change
          </button>
        </div>
      </form>
    </div>
  );

  const renderSettingsOptions = () => (
    <div className="settings_options">
      <Option
        calling="changePassword"
        label="Change Password"
        icon={<TbCircleKey />}
      />
      <Option calling="support" label="Support" icon={<GoShieldCheck />} />
      {/* <Option
        calling="about"
        label="About"
        icon={<IoIosInformationCircleOutline />}
      /> */}
      <Option
        calling="terms"
        label="Terms & Conditions"
        icon={<MdOutlinePlaylistAddCheckCircle />}
      />
      <Option
        calling="privacy"
        label="Privacy Policy"
        icon={<HiOutlineClipboardDocumentCheck />}
      />
      <Option calling="delete" label="Delete Account" icon={<FiUserX />} />
    </div>
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageChange(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        setEditFormData((prevFormData) => ({
          ...prevFormData,
          // image: [...prevFormData.image, e.target.result]
          image: file,
        }));
        setProfile(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const deleteAccount = async () => {
    try {
      startLoading();
      const response = await api.deleteAccount();
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        signOutUser(navigate);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  //============================ Cancel Subscription =================================
  // const [otp, setOtp] = React.useState(0);
  const [otp, setOtp] = useState(['', '', '', '']);


  // Handle input change for each field
  const handleChangeCancel = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === '') {  // Only allow single digit numbers
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input if a digit is entered
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  // Handle backspace for navigating to previous input
  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const [finalCancelConfirm, setFinalCancelConfirm] = React.useState(false);
  const [showOtpView, setShowOtpView] = React.useState(false);

  const sendCancelRequestOtp = async () => {
    const response = await api.SendCancelSubsOtp();
    if (response.status === 1) {
      setShowOtpView(true)
      // alert('Otp sent successfully')
      toast.success(response.message);

      // logMessage('Otp sent successfully', "alert");
    } else if (response.status === 2) {
      setShowOtpView(true)
      toast.success(response.message);
      // logMessage(response.message, "alert");
    }
    else {
      // alert(response.message)
      toast.error(response.message); 
    }
  };
  const VerifyCancelRequestOtp = async () => {
    const response = await api.VerifyCancelSubsOtp({ otp });
    if (response.status === 1) {
      setFinalCancelConfirm(true)
    } else {
      // alert(response.message)
      logMessage(response.message, "alert");

    }
  };
  const cancelSub = async () => {
    try {
      showButtonLoading(true);
      const data = {
        sub_id: subId,
      };
      const response = await api.cancelSubscription(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        window.location.reload();
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      showButtonLoading(false);
    }
  };
  //============================ Cancel Subscription =================================˝
  const getProfileData = async () => {
    try {
      startLoading();
      const response = await api.getProfile();
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        const signUpResponse = new UserData(response.data);

        localStorage.setItem("user_data", JSON.stringify(signUpResponse.data));
        localStorage.setItem("user_email", signUpResponse.email);
        localStorage.setItem("user_name", signUpResponse.name);
        localStorage.setItem("profile_picture", signUpResponse.profile_picture);
        logMessage("Update runs");
        logMessage(signUpResponse.profile_picture);

        // setTimeout(() => {
        setName(signUpResponse.name);
        setEditFormData({
          image: signUpResponse.profile_picture,
          name: signUpResponse.name,
        });
        setProfile(signUpResponse.profile_picture);
        getSubDetail();
        // }, 1000);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    }
  };
  const getSubDetail = async () => {
    try {
      startLoading();
      const response = await api.getSubscriptionDetail();
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        const subModel = new SubModel(response.data);
        if (subModel.isSubscribed == "true") {
          setUserType("Premium");
          localStorage.setItem('isVerified', true);
          setInterval(subModel.interval);
          setIntervalCount(subModel.intervalCount);
          setAmount(subModel.amount);
          setSubId(subModel.sub_id);
          setPurchasedOn(subModel.purchased_on);
          setExpiryTime(subModel.expiry_time);
          setCurrency(subModel.currency);
          setStatus(subModel.status);
        } else {
          setUserType("Basic");
        }
        stopLoading();
        // }, 1000);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    // console.log("calledgain")
    getProfileData();
    // getProductsDetail();
  }, []);

  const uploadImageClick = (e) => {
    e.preventDefault();
    logMessage("clicked");
    document.getElementById("imageUploadInput").click();
  };

  return (
    <div className="full_page_inner">
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        className="dashboard_container profile_container"
        style={{ backgroundImage: `url(${imagePaths.profile_back})` }}
      >
        <div className="home_container profile_outer_container">
          {showPopup && (
            <div className="popup-overlay">
              <div className="popup">
                <h2>Delete Account</h2>
                <p>Are you sure you want to delete account</p>
                <div className="warning">
                  <div className="warningtextbox">
                    <p className="warningtext">
                      <IoIosWarning /> Warning
                    </p>
                  </div>

                  <p>
                    By deleting the account you will loose all your projects
                    data and media
                  </p>
                </div>
                <button className="delete-button" onClick={deleteAccount}>
                  Yes, Delete Account
                </button>
                <button
                  className="cancel-button canceldelacc"
                  onClick={togglePopup}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          {showVerifyPopUp && (
            <div className="popup-overlay">
              <div className="popup">
                <h2>Verify Payment</h2>
                <p>Close the window and click on continue to verify payment ?  </p>
                <button className="delete-button" onClick={refreshPage}>
                  Yes, Verify Payment
                </button>
              </div>
            </div>
          )}
          {showSubPopUp && (

            finalCancelConfirm
              ?
              < div className="popup-overlay">
                <div className="popup subPopCancellation"
                style={{
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${imagePaths.backgroundStars})`,
                }}
                >
                <div className="innerDetails" 
                        >
                        <img alt="crownImage" className="crownImage" src={imagePaths.crown}></img>
                    <h2 style={{margin: 'auto'}}>Downgrade Subscription Plan</h2>
                    </div>
                <div className="subwarning">
                    {/* <div className="warningtextbox">
                      <p className="warningtext">
                        <IoIosWarning /> Warning
                      </p>
                    </div> */}
                    <p 
                    style={{color:"#E84343",
                      fontSize:"12px"

                    }}  
                    >
                    Are you sure you want to downgrade to basic plan?
                    </p>
                  </div>
                  <button className="delete-button" style={{background:"#BE2424", border:"none"}} onClick={cancelSub}>
                    {buttonLoading ? "Cancelling..." : "Yes, Downgrade"}
                  </button>
                  <button
                    className="cancel-button canceldelacc"
                    onClick={() => setFinalCancelConfirm(false)}
                  >
                    Not Now
                  </button>
                </div>
              </div>
              :
              showOtpView
                ?
                < div className="popup-overlay">
                  <div className="popup subPopCancellation otherCancellation">
                  <p onClick={() => { setShowSubPopUp(!showSubPopUp); setShowOtpView(false) }} style={{textAlign:'right', color:"#552F83", fontSize:"13px", cursor:"pointer"}}>Close</p>
                    <img style={{width:'130px'}} src={imagePaths.diamond_img}></img>
                    <p className="otpverificationtitle">OTP Verification</p>
                    <p className="otpverificationdescription">Enter the verification code we just sent on your email address.</p>
                    {/* <div className="warning">
                      <div className="warningtextbox">
                        <p className="warningtext">
                          <IoIosWarning /> Warning
                        </p>
                      </div>

                      <p>
                        Your subscription will be cancelled, but you’ll continue to
                        enjoy full access and benefits until it expires.
                      </p>
                    </div> */}
                    <div style={styles.otpContainer}>
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="text"
                          inputMode="numeric"
                          maxLength="1"
                          value={digit}
                          onChange={(e) => handleChangeCancel(e, index)}
                          onKeyDown={(e) => handleBackspace(e, index)}
                          style={styles.otpInput}
                          id={`otp-input-${index}`}
                        />
                      ))}
                    </div>
                    {/* <input type="number" value={otp} onChange={(e) => setOtp(e.target.value)} className="inputAndIconSubs" /> */}
                   
                    <button
                      className="delete-button"
                      onClick={() => { setShowSubPopUp(!showSubPopUp); setShowOtpView(false) }}
                    >
                      Cancel Downgrade
                    </button>
                    <button className="cancel-button canceldelacc" onClick={VerifyCancelRequestOtp}>
                      {buttonLoading ? "Verifying..." : "Submit"}
                    </button>
                    <p onClick={sendCancelRequestOtp} className="resendcode">Didn't received code? <span className="resendcodetext">{buttonLoading ? "Sending" : "Resend"}</span></p>
                  </div>
                </div>
                :
                < div className="popup-overlay">
                  <div className="popup subPopCancellation"
                   style={{
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${imagePaths.backgroundStars})`,
                  }}
                  >
                     <div className="innerDetails" 
                        >
                        <img className="crownImage" src={imagePaths.crown}></img>
                    <h2 style={{margin: 'auto'}}>Downgrade Subscription Plan</h2>
                    </div>
                    {/* <p>Are you sure you want to cancel subscription</p> */}
                    <div className="warning subwarning">
                      <div className="warningtextbox">
                        <p className="warningtext">
                          {/* <IoIosWarning /> */}
                          {/* Warning */}
                        </p>
                      </div>
                      <p className="cancelTitle" style={{ margin: "0.5rem 0", color: '#E84343', fontWeight:'600'}}>Canceling your subscription will result in losing access to the following features:</p>
                    </div>
                    <ul className="benefitsList">
                      <li>Unlimited project creation: Only your first 5 projects will remain visible. Any additional projects will be saved but will only become accessible again if you upgrade.</li>
                      <li>The Leaderboard section and eligibility to win prizes.</li>
                      <li>Desktop version access: You will only be able to use the mobile version of the app.</li>
                      <li>Exclusive member discounts.</li>
                      <li>Priority support.</li>
                    </ul>
                    <button className="delete-button canceldowngrade" onClick={() => setShowSubPopUp(!showSubPopUp)}>
                        Cancel Downgrade                    
                        </button>
                    <button className="delete-button cancelSubClass" onClick={sendCancelRequestOtp}>
                      {buttonLoading ? "Cancelling..." : "Downgrade Subscription"}
                    </button>
                  </div>
                </div>

          )}
          <div className="profile_inner_container">
            <div className="left_profile_container">
              <div onClick={uploadImageClick} className="image box">
                <img src={profile || imagePaths.demo_profile} alt="Profile" />
                <div className="camera-icon">
                  <FaCamera />
                </div>
              </div>
              <input
                style={{ display: "none" }}
                type="file"
                id="imageUploadInput"
                accept="image/png, image/jpeg"
                onChange={handleFileChange}
              />
              <p
                style={{ fontSize: "22px", fontWeight: "500" }}
                className="name userprofilename"
              >
                {name}
              </p>
              <p
                style={{ fontSize: "16px" }}
                className="email userprofileemail"
              >
                {email}
              </p>

              <div className="subDetailBox"
              style={{
                padding: "1px 1px 1px 1px" ,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${imagePaths.backgroundStars})`,
              }}
              >

                {/* <p
                  style={{ fontSize: "16px", fontWeight: "500" }}
                  className="email userprofileemail"
                >
                  {" "}
                </p> */}
                {userType !== "" && (
                  <div>
                    {userType === "Premium" ? (
                      <div style={{ padding: "1px 0px 0px 0px" }}>
                          <div className="innerDetails" 
                        >
                        <img alt="crownImage" className="crownImage" src={imagePaths.crown}></img>
                        <p className="email userprofileemail userTypePara"
                          style={{ fontSize: "14px", fontWeight: "600",
                        }}
                        >
                           {interval === "MONTH" ? "Monthly Subscription" : "Annual Subscription"}
                        </p>
                        </div> 
                        {status === "ACTIVE" ? (
                          <div>
                           <div className="innerTiles">
                            
                        <p
                          className="email userprofileemail"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          Current Status:
                        </p>
                        <div className="innerDetails" 
                        >
                        <img alt="tickImage" className="tickImage" src={imagePaths.tick}></img>
                        <p
                         className="email userprofileemail rightSideSubText"
                        style={{
                          fontSize: "14px",
                          // textAlign: "center",
                        }}>
                        {status}
                        </p>
                        </div>
                        </div>
                        <div className="innerTiles">
                        <p
                          className="email userprofileemail"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          Renewal Date:
                        </p>
                        <p
                         className="email userprofileemail rightSideSubText"
                        style={{
                          fontSize: "14px",
                          // textAlign: "center",
                        }}>
                        {expiryTime}
                        </p>
                        </div>
                        <div className="innerTiles lastSubTile">
                        <p
                          className="email userprofileemail"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          Renewal Price:
                        </p>
                        <p
                         className="email userprofileemail rightSideSubText"
                        style={{
                          fontSize: "14px",
                          // textAlign: "center",
                        }}>
                         ${parseFloat(amount)}
                        </p>
                        </div>
                          </div>
                        ) : (
                          <div>
                          <div className="innerTiles">
                        <p
                          className="email userprofileemail"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          Current Status:
                        </p>
                        <p
                         className="email userprofileemail rightSideSubText warningDate"
                        style={{
                          fontSize: "14px",
                          // textAlign: "center",
                        }}>
                        {status}
                        </p>
                        </div>
                        <div className="innerTiles">
                        <p
                          className="email userprofileemail"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          Valid Upto:
                        </p>
                        <p
                         className="email userprofileemail rightSideSubText warningDate"
                        style={{
                          fontSize: "14px",
                          // textAlign: "center",
                        }}>
                        {expiryTime}
                        </p>
                        </div>
                        <div className="innerTiles lastSubTile">
                        <p
                          className="email userprofileemail"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          Renewal Price:
                        </p>
                        <p
                         className="email userprofileemail rightSideSubText"
                        style={{
                          fontSize: "14px",
                          // textAlign: "center",
                        }}>
                        ${parseFloat(amount)}
                        </p>
                        </div>
                        </div>
                        )}
                      </div>
                    ) : (
                      <div style={{ padding: "1px 1px 1px 1px" ,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url(${imagePaths.backgroundStars})`,
                      }}>
                        <div className="innerDetails" 
                        style={{marginBottom:"20px"}}
                        >
                        <img alt="crownImage" className="crownImage" src={imagePaths.crown}></img>
                        <p className="email userprofileemail userTypePara"
                          style={{ fontSize: "14px", fontWeight: "600",
                        }}
                        >
                          {userType}
                        </p>
                        </div> 
                        <button
                          style={{ marginBottom: "10px" }}
                          onClick={openSubscribeBox}
                          className="addProject"
                        >
                          View Plans
                        </button>
                      </div>
                    )}
                  </div>
                )}
                
              </div>

              {status === "ACTIVE" && (
                <button
                  style={{ margin: "20px 10px 10px 10px", fontWeight:'600 !important'}}
                  className="addProject cancelSubClass"
                  onClick={() => setShowSubPopUp(!showSubPopUp)}
                >
                  Cancel Plan
                </button>
              )}

            </div>
            <div className="right_profile_container">
              <div className="tabs_profile">
                <div
                  onClick={() => handleChange("personal")}
                  className={type === "personal" ? "tab active" : "tab"}
                >
                  Personal Information
                </div>
                <div
                  onClick={() => handleChange("settings")}
                  className={type === "settings" ? "tab active" : "tab"}
                >
                  Settings
                </div>
              </div>
              <div className="info_content">
                {getTypeComponent(type)}
                {/* {type === 'personal' ? (
                                    <div className='personal_info'>
                                        <form>
                                            <div className="form_container perosnal_form">
                                                <div className='form_fields'>
                                                    <label>Name:</label>
                                                    <input type="text" name="design" value={name} />
                                                </div>
                                                <div className='form_fields'>
                                                    <label>Email:</label>
                                                    <input type="text" name="artist" value={email} readOnly />
                                                </div>
                                            </div>

                                            <button className="addProject" type="submit">Update</button>
                                        </form>
                                    </div>
                                ) : (
                                    <div className="settings_options">
                                        <Option label="Change Password" icon={<TbCircleKey />} />
                                        <Option label="Support" icon={<GoShieldCheck />} />
                                        <Option label="About" icon={<IoIosInformationCircleOutline />} />
                                        <Option label="Terms & Conditions" icon={<MdOutlinePlaylistAddCheckCircle />} />
                                        <Option label="Privacy Policy" icon={<HiOutlineClipboardDocumentCheck />} />
                                        <Option label="Delete Account" icon={<FiUserX />} />
                                    </div>
                                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default MyProfile;
