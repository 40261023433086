import React, { useState, useEffect } from "react";
import Header from "../../components/common/header";
import imagePaths from "../../assets/imagePaths";
import { websiteLink } from "../../utils/helpers";
import { DataModel } from "../../models/projectModel";
import api from "../../api/api.js";
import paths from "../../utils/constants.js";
import {
  convertSecondsToHoursLive,
  convertText,
  logMessage,
} from "../../utils/helpers.js";
import { ApiResponse } from "../../models/ApiResponseModel.js";
import { LuRadar } from "react-icons/lu";
import { FaRegCirclePlay, FaRegCirclePause } from "react-icons/fa6";
import { convertSecondsToHours } from "../../utils/helpers.js";
import { useNavigate } from "react-router-dom";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";

const Shuffle = ({ startLoading, stopLoading }) => {
  const navigate = useNavigate();
  const [textToCopy, setTextToCopy] = useState(websiteLink);
  const [projectData, setProjectData] = useState(new DataModel({}));
  const [showUI, setShowUI] = useState(false);
  const [isShuffling, setIsShuffling] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [visibleIndex, setVisibleIndex] = useState(1);
  const [offset, setOffset] = useState(0);
  const [timer, setTimer] = useState(null);
  const demoData = ["amit", "khajuria", "rajat", "sharma"];

  const shuffleItems = () => {
    if (projectData.projects.length > 3) {
      setIsShuffling(true);
      const items = projectData.projects;
      let count = 0;

      let shuffled = [...items];
      const shuffleAndSet = () => {
        logMessage(shuffled);
        const temp = shuffled[shuffled.length - 1];
        for (let i = shuffled.length - 1; i > 0; i--) {
          shuffled[i] = shuffled[i - 1];
        }
        shuffled[0] = temp;
        const newProjectData = new DataModel({
          ...projectData,
          projects: shuffled,
        });
        setProjectData(newProjectData);
      };

      const intervalId = setInterval(() => {
        shuffleAndSet();
        count++;

        if (count === 20) {
          clearInterval(intervalId);
          setIsShuffling(false);
        }
      }, 100);
    }
  };

  const shuffleItemsOld = () => {
    setIsShuffling(true);

    const element = document.getElementById("rotationtable");

    if (element) {
      const scrollHeight = element.scrollHeight;
      const clientHeight = element.clientHeight;
      const start = element.scrollTop;
      const distance = scrollHeight - clientHeight - start;
      const duration = 12000;
      let startTime = null;


      const scrollStep = (currentTime) => {
        if (!startTime) startTime = currentTime;
        const progress = currentTime - startTime;
        const percent = Math.min(progress / duration, 1);
        element.scrollTop = start + distance * percent;
        if (progress < duration) {
          requestAnimationFrame(scrollStep);
        } else {
          const items = projectData.projects;
          const forbiddenId = selectedId;
          let shuffled = [...items];
          for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
          }
          if (shuffled[1] && shuffled[1]._id === forbiddenId) {
            // Find a different position to swap with
            for (let k = 2; k < shuffled.length; k++) {
              if (shuffled[k]._id !== forbiddenId) {
                [shuffled[1], shuffled[k]] = [shuffled[k], shuffled[1]];
                break;
              }
            }
          }

          if (shuffled[1]) {
            setSelectedId(shuffled[1]._id);
          }
          const newProjectData = new DataModel({
            ...projectData,
            projects: shuffled,
          });
          setProjectData(newProjectData);

          element.scrollTop = 0;

          setIsShuffling(false);
        }
      };

      requestAnimationFrame(scrollStep);
    } else {
      console.error("Element with ID 'rotationtable' not found.");
      setIsShuffling(false);
    }
  };

  const fetchProjects = async () => {
    setProjectData(new DataModel({}));
    try {
      startLoading();
      const data = {
        type: "not_started",
        page: 1,
        limit: 100000,
      };
      const response = await api.getProjectList(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        logMessage(response.data);
        let projectData = new DataModel(response.data);
        logMessage(projectData.projects.length);
        if (
          projectData.projects.length < 20 &&
          projectData.projects.length > visibleIndex
        ) {
          const duplicateCount =
            Math.ceil(20 / projectData.projects.length) -
            1 +
            Math.ceil(20 / projectData.projects.length) -
            1 +
            Math.ceil(20 / projectData.projects.length) -
            1 +
            Math.ceil(20 / projectData.projects.length) -
            1;
            logMessage("duplicateCount");
          logMessage(duplicateCount);
          const duplicatedProjects = [...projectData.projects];
          for (let i = 0; i < duplicateCount; i++) {
            duplicatedProjects.push(...projectData.projects);
          }
          setSelectedId(duplicatedProjects[1]._id);
          projectData = new DataModel({
            ...projectData,
            projects: duplicatedProjects,
          });
          setProjectData(projectData);
        } else {
          setProjectData(projectData);
        }

        stopLoading();
        setShowUI(true);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleTimer = async (type, project_id) => {
    // if (type == "stop") {
    //   stop_timer(project_id);
    // } else {
    //   start_timer(project_id);
    // }
    // logMessage("handle timer clicked");
    // navigate(paths.PROJECT_DETAIL + "?project_id=" + project_id);
  };

  const start_timer = async (project_id) => {
    try {
      startLoading();
      const data = {
        project_id: project_id,
      };
      const response = await api.startTimer(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        fetchProjects();
        stopLoading();
        setShowUI(true);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
      setShowUI(true);
    }
  };
  const openProjectDetail = (project_id) => {
    navigate(paths.PROJECT_DETAIL + "?project_id=" + project_id);
  };
  const selectProject = () => {
    navigate(paths.PROJECT_DETAIL + "?project_id=" + selectedId);
  };
  const stop_timer = async (project_id) => {
    try {
      startLoading();
      const data = {
        project_id: project_id,
      };
      const response = await api.stopTimer(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        fetchProjects();
        stopLoading();
        setShowUI(true);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };

  return (
    <div className="full_page_inner" style={{ overflow: "hidden" }}>
      <Header />
      <div className="dashboard_container">
        <div className="shuffle_container">
          <div className="innerData">
            <div className="table_container shuffle_container">
              {/* <h4 className='table_title'>Current Projects</h4> */}
              <div className="table_inner_card">
                {projectData.projects && projectData.projects.length > 0 ? (
                  <div className="inner_grouping">
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Design</th>
                          <th>Artist</th>
                          <th>Brand</th>
                          <th>Logged time</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="inner_table">
                      {!isShuffling && selectedId !== "" && (
                        <button
                          className="center-button"
                          onClick={() => selectProject()}
                        >
                          Pick Project
                        </button>
                      )}
                      {projectData.projects.length > visibleIndex && (
                        <div className="tableoverlay"></div>
                      )}
                      {projectData.projects.length > visibleIndex && (
                        <div className="lowertableoverlay"></div>
                      )}
                      {projectData.projects.length > visibleIndex && (
                        <div className="arrow start-arrow">
                          <IoMdArrowDropright />
                        </div>
                      )}
                      {projectData.projects.length > visibleIndex && (
                        <div className="arrow end-arrow">
                          <IoMdArrowDropleft />
                        </div>
                      )}
                      {projectData.projects.length > visibleIndex ? (
                        <table className="rotation_table" id="rotationtable">
                          <tbody>
                            {projectData.projects.length > 0 ? (
                              projectData.projects.map((project, index) => {
                                let className = "";
                                if (isShuffling) {
                                  if (projectData.projects.length > 2) {
                                    className = "shuffle-row ";
                                    if (index === visibleIndex) {
                                      className += "visible secondindex";
                                    } else {
                                      className += "visible";
                                    }
                                  } else {
                                    className = "shuffle-row visible";
                                  }
                                } else {
                                  if (projectData.projects.length > 2) {
                                    if (index === visibleIndex) {
                                      className = "visible secondindex";
                                    } else {
                                      className = "visible";
                                    }
                                  }
                                }
                                return (
                                  <tr
                                    className={className}
                                    style={{ border: "none" }}
                                    key={index}
                                  >
                                    <td>
                                      <img
                                        alt="project_image"
                                        className="table_image"
                                        src={project.image[0]}
                                      ></img>
                                    </td>
                                    <td className="design_text">
                                      {project.design}
                                    </td>
                                    <td>© {project.artist}</td>
                                    <td>{project.brand}</td>
                                    <td>
                                      {" "}
                                      {project && (
                                        <>
                                          {project.timer_status ===
                                          "started" ? (
                                            <>
                                              <FaRegCirclePause
                                                className="timer_duration"
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  handleTimer(
                                                    "stop",
                                                    project._id
                                                  );
                                                }}
                                              />
                                              {convertSecondsToHours(
                                                project.totalDuration
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <FaRegCirclePlay
                                                className="timer_duration"
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  handleTimer(
                                                    "start",
                                                    project._id
                                                  );
                                                }}
                                              />
                                              {convertSecondsToHours(
                                                project.totalDuration
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}{" "}
                                    </td>
                                    <td>
                                      <span className={project.project_status}>
                                        {" "}
                                        <LuRadar />{" "}
                                        {convertText(project.project_status)}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <div className="no_project">
                                {showUI && (
                                  <div>
                                    <img
                                      src={imagePaths.nofile}
                                      alt="No projects"
                                    />
                                    <p>Currently, you don't have any projects started.</p>
                                  </div>
                                )}
                              </div>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <table>
                          <tbody>
                            {projectData.projects.length > 0 ? (
                              projectData.projects.map((project, index) => {
                                let className = "";
                                if (isShuffling) {
                                  if (projectData.projects.length > 2) {
                                    className = "shuffle-row ";
                                    if (index === visibleIndex) {
                                      className += "visible secondindex";
                                    } else {
                                      className += "visible";
                                    }
                                  } else {
                                    className = "shuffle-row visible";
                                  }
                                } else {
                                  if (projectData.projects.length > 2) {
                                    if (index === visibleIndex) {
                                      className = "visible secondindex";
                                    } else {
                                      className = "visible";
                                    }
                                  }
                                }
                                return (
                                  <tr
                                    className={className}
                                    style={{ border: "none" }}
                                    key={index}
                                    onClick={() =>
                                      openProjectDetail(project._id)
                                    }
                                  >
                                    <td>
                                      <img
                                        alt="project_image"
                                        className="table_image"
                                        src={project.image[0]}
                                      ></img>
                                    </td>
                                    <td className="design_text">
                                      {project.design}
                                    </td>
                                    <td>© {project.artist}</td>
                                    <td>{project.brand}</td>
                                    <td>
                                      {" "}
                                      {project && (
                                        <>
                                          {project.timer_status ===
                                          "started" ? (
                                            <>
                                              <FaRegCirclePause
                                                className="timer_duration"
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  handleTimer(
                                                    "stop",
                                                    project._id
                                                  );
                                                }}
                                              />
                                              {convertSecondsToHours(
                                                project.totalDuration
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <FaRegCirclePlay
                                                className="timer_duration"
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  handleTimer(
                                                    "start",
                                                    project._id
                                                  );
                                                }}
                                              />
                                              {convertSecondsToHours(
                                                project.totalDuration
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}{" "}
                                    </td>
                                    <td>
                                      <span className={project.project_status}>
                                        {" "}
                                        <LuRadar />{" "}
                                        {convertText(project.project_status)}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <div className="no_project">
                                {showUI && (
                                  <div>
                                    <img
                                      src={imagePaths.nofile}
                                      alt="No projects"
                                    />
                                    <p>Currently, you don't have any projects started</p>
                                  </div>
                                )}
                              </div>
                            )}
                          </tbody>
                        </table>
                      )}

                      {/* <div className="visible-window">
                                                <div className='projects_detail'>
                                                   <p>{demoData[0]}</p> 
                                                </div> */}
                      {/* <div className="top_blur">
                                                   
                                                </div>
                                                <div className="center_visible">

                                                </div>
                                                <div className="bottom_blur">

                                                </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                ) : (
                  <div className="no_project">
                    {showUI && (
                      <div>
                        <img src={imagePaths.nofile} alt="No projects" />
                        <p>Currently, you don't have any projects started.</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <h1
          // onClick={() => !isShuffling && shuffleItemsOld()}
          className="footer-title"
        >
          Ready, Set, SHUFFLE!
        </h1>
        <img
          onClick={() => !isShuffling && shuffleItemsOld()}
          style={{ width: "80px", height:"80px", cursor:"pointer", marginLeft:"50px" }}
          src={imagePaths.shuffle}
        ></img>
      </footer>
    </div>
  );
};

export default Shuffle;
