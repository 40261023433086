
export class SubModel {
    constructor({ sub_id, purchased_on, expiry_time, billing, isSubscribed, amount, interval, intervalCount, currency, status, product_title, product_image, payment_status, order_number }) {
        this.sub_id = sub_id;
        this.purchased_on = purchased_on;
        this.expiry_time = expiry_time;
        this.billing = billing;
        this.isSubscribed = isSubscribed;
        this.amount = amount;
        this.interval = interval;
        this.intervalCount = intervalCount;
        this.currency = currency;
        this.status = status;
        this.product_title = product_title;
        this.product_image = product_image;
        this.payment_status = payment_status;
        this.order_number = order_number;
    }
}
