import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import IntroScreen from './screens/IntroScreen';
import paths from './utils/constants';
import AuthScreen from './screens/AuthScreen/authScreen';
import ForgotPasswordScreen from './screens/AuthScreen/forgotPassword';
import OtpScreen from './screens/AuthScreen/otpScreen';
import ChangePasswordScreen from './screens/AuthScreen/changePassword';
import HomeScreen from './screens/Dashboard/homeScreen';
import { Bars } from 'react-loader-spinner';
import ProjectScreen from './screens/Dashboard/projectScreen';
import ProjectDetail from './screens/Dashboard/projectDetail';
import AddProject from './screens/Dashboard/addProject';
import MyProfile from './screens/Dashboard/myprofile';
import TermsAndConditions from './screens/OtherScreens/terms';
import PrivacyPolicy from './screens/OtherScreens/privacy';
import Shop from './screens/Dashboard/shop';
import Shuffle from './screens/Dashboard/shuffle';
import AboutUs from './screens/OtherScreens/about';
import SearchScreen from './screens/Dashboard/searchScreen';
import EditProject from './screens/Dashboard/editProject';
import LeaderBoard from './screens/Dashboard/leaderboard';

function App() {
  const [loading, setLoading] = useState(false);

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  return (
    <Router>
      {loading && (
        <div className="loader-container">
          <Bars color="#552F83" loading={loading} height="40" width="40" />
        </div>
      )}
      <Routes>
        <Route path={paths.INTRO_SCREEN} exact element={<IntroScreen />} />
        <Route path={paths.AUTH_SCREEN} exact element={<AuthScreen startLoading={startLoading} stopLoading={stopLoading} />} />
        <Route path={paths.FORGOT_PASSWORD_SCREEN} exact element={<ForgotPasswordScreen startLoading={startLoading} stopLoading={stopLoading} />} />
        <Route path={paths.ENTER_OTP_SCREEN} exact element={<OtpScreen startLoading={startLoading} stopLoading={stopLoading} />} />
        <Route path={paths.CHANGE_PASSWORD_SCREEN} exact element={<ChangePasswordScreen startLoading={startLoading} stopLoading={stopLoading} />} />
        <Route path={paths.HOME_SCREEN} exact element={<HomeScreen startLoading={startLoading} stopLoading={stopLoading} />} />
        <Route path={paths.PROJECT_SCREEN} exact element={<ProjectScreen startLoading={startLoading} stopLoading={stopLoading} />} />
        <Route path={paths.PROJECT_DETAIL} exact element={<ProjectDetail startLoading={startLoading} stopLoading={stopLoading} />} />
        <Route path={paths.ADD_PROJECT} exact element={<AddProject startLoading={startLoading} stopLoading={stopLoading} />} />
        <Route path={paths.EDIT_PROJECT} exact element={<EditProject startLoading={startLoading} stopLoading={stopLoading} />} />
        <Route path={paths.MY_PROFILE} exact element={<MyProfile startLoading={startLoading} stopLoading={stopLoading} />} />
        <Route path={paths.SEARCH} exact element={<SearchScreen startLoading={startLoading} stopLoading={stopLoading} />} />
        <Route path={paths.TERMS} exact element={<TermsAndConditions />} />
        <Route path={paths.PRIVACY} exact element={<PrivacyPolicy />} />
        <Route path={paths.ABOUTUS} exact element={<AboutUs />} />
        <Route path={paths.SHOP} exact element={<Shop />} />
        <Route path={paths.SHUFFLE} exact element={<Shuffle startLoading={startLoading} stopLoading={stopLoading} />} />
        <Route path={paths.LEADERBOARD} exact element={<LeaderBoard startLoading={startLoading} stopLoading={stopLoading} />} />
      </Routes>
    </Router>
  );
}

export default App;
