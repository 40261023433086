const paths = {
    INTRO_SCREEN: '/',
    AUTH_SCREEN: '/auth',
    FORGOT_PASSWORD_SCREEN: '/forgotpassword',
    ENTER_OTP_SCREEN: '/enterotp',
    CHANGE_PASSWORD_SCREEN: '/changepassword',
    HOME_SCREEN: '/home',
    PROJECT_SCREEN: '/projects',
    PROJECT_DETAIL: '/projects-detail',
    ADD_PROJECT: '/add-project',
    MY_PROFILE: '/my-profile',
    TERMS: '/termsconditions',
    PRIVACY: '/privacypolicy',
    SHOP: '/shop',
    SHUFFLE: '/shuffle',
    ABOUTUS: '/about',
    SEARCH: '/search',
    EDIT_PROJECT: '/edit-project',
    LEADERBOARD:'/leaderboard'
};


export default paths;