class Participant {
    constructor({
        total_points = 0,
        totalDuration = 0,
        numberOfProjects = 0,
        rank=0,
        is_deleted = false,
        profile_picture = "",
        userId = "",
        name = "",
    }) {
        this.rank = rank;
        this.userId = userId;
        this.name = name;
        this.profile_picture = profile_picture;
        this.is_deleted = is_deleted;
        this.numberOfProjects = numberOfProjects;
        this.totalDuration = totalDuration;
        this.total_points = total_points;
    }
}

class LeaderboardOuter {
    constructor({
        participants = [],
        type="current",
        totalProjects = 0,
        hasNextPage = false,
        currentPage = 0,
        totalPages = 0,
        first = null,
        second = null,
        third = null,
    }) {
        this.participants = participants.map(participant => new Participant(participant));
        this.type = type;
        this.totalProjects = totalProjects;
        this.hasNextPage = hasNextPage;
        this.currentPage = currentPage;
        this.totalPages = totalPages;
        this.first = first ? new Participant(first) : null;
        this.second = second ? new Participant(second) : null;
        this.third = third ? new Participant(third) : null;
    }
}

export { Participant, LeaderboardOuter };
